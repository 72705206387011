<template>
  <div class="search-tools">
    <h1 class="fatty">Find-A-Camp</h1>
    <p>Search by name of a camp, county (i.e. Cumberland) or keyword(s) by using the input field below...</p>
    <div class="field">
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input 
              v-model="searchTerm"
              class="input"
              type="text"
              placeholder="Enter search terms">
          </div>
        </div>
      </div>
    </div>
    <hr>
    <p>...or select any combination of filters to see only camps which offer the following:</p>
    <div class="field">
      <div class="field-label">
        <label class="label">Camp Category:</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <template v-if="!availableCategories.hasOwnProperty('camp-category')">
              <span class="icon"><i class="fas fa-spin fa-spinner"/></span>Fetching...
            </template>
            <template 
              v-else 
              v-for="check in availableCategories['camp-category']">
              <label 
                :key="check.term_id" 
                :for="'r-' + check.term_id" 
                class="radio">
                <input 
                  :id="'r-' + check.term_id" 
                  type="radio" 
                  v-model="selectedCampCategory" 
                  :value="check.name.replace('amp;', '')">
                {{ check.name.replace('amp;', '') }}
              </label>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="field">
      <div class="field-label">
        <label class="label">Camp Type:</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <template v-if="!availableCategories.hasOwnProperty('camp-type')">
              <span class="icon"><i class="fas fa-spin fa-spinner"/></span>Fetching...
            </template>
            <template 
              v-else 
              v-for="check in availableCategories['camp-type']">
              <label 
                :key="check.term_id" 
                class="checkbox">
                <input 
                  v-model="selectedCampType" 
                  :key="check.term_id" 
                  :value="check.name" 
                  type="checkbox">
                {{ check.name.replace('amp;', '') }}
              </label>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="field">
      <div class="field-label">
        <label class="label">Session Length:</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <template v-if="!availableCategories.hasOwnProperty('session-length')">
              <span class="icon"><i class="fas fa-spin fa-spinner"/></span>Fetching...
            </template>
            <template 
              v-else 
              v-for="check in availableCategories['session-length']">
              <label 
                class="checkbox"
                :key="check.term_id">
                <input 
                  v-model="selectedSessionLength" 
                  :key="check.term_id" 
                  :value="check.name" 
                  type="checkbox">
                {{ check.name.replace('amp;', '') }}
              </label>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="field">
      <div class="field-label">
        <label class="label">Tuition per Week:</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <template v-if="!availableCategories.hasOwnProperty('tuition-range')">
              <span class="icon"><i class="fas fa-spin fa-spinner"/></span>Fetching...
            </template>
            <template 
              v-else 
              v-for="check in availableCategories['tuition-range']">
              <label 
                class="checkbox"
                :key="check.term_id">
                <input 
                  v-model="selectedTuitionRange" 
                  :key="check.term_id" 
                  :value="check.name"
                  type="checkbox">
                {{ check.name.replace('amp;', '') }}
              </label>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="field">
      <div class="field-label">
        <label class="label">Advanced Categories:</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <template v-if="!availableCategories.hasOwnProperty('advanced-search-cats')">
              <span class="icon"><i class="fas fa-spin fa-spinner"/></span>Fetching...
            </template>
            <template 
              v-else 
              v-for="check in availableCategories['advanced-search-cats']">
              <label 
                class="checkbox"
                :key="check.term_id">
                <input 
                  v-model="selectedAdvancedCategories"  
                  :key="check.term_id" 
                  :value="check.name" 
                  type="checkbox">
                {{ check.name.replace('amp;', '') }}
              </label>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="field">
      <div class="field-label">
        <label class="label">Activities:</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <template v-if="!availableCategories.hasOwnProperty('activities')">
              <span class="icon"><i class="fas fa-spin fa-spinner"/></span>Fetching...
            </template>
            <template 
              v-else 
              v-for="check in availableCategories['activities']">
              <label 
                class="checkbox"
                :key="check.term_id">
                <input 
                  v-model="selectedActivities" 
                  :key="check.term_id" 
                  :value="check.name" 
                  type="checkbox">
                {{ check.name.replace('amp;', '') }}
              </label>
            </template>
          </div>
        </div>
      </div>
    </div>
    <button class="button is-primary close-search">
      <i 
        v-if="processing"
        class="fas fa-btn fa-spin fa-spinner"/> 
      <span v-else>{{ buttonText }}</span>
    </button>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        processing: false,
        noResults: false,
        searchTerm: this.getLocalData('searchTerm', ''),
        allCamps: {},
        searchOptions: {
          // keys: ['post_title', 'post_content', 'post_excerpt'],
          keys: ['post_title', 'post_content', 'post_excerpt', 'all_cats', 'categories'],
          threshold: 0.2
        },
        availableCategories: [],
        selectedCategories: false,
        selectedCampCategory: this.getLocalData('selectedCampCategory'),
        selectedCampType: this.getLocalData('selectedCampType'),
        selectedSessionLength: this.getLocalData('selectedSessionLength'),
        selectedTuitionRange: this.getLocalData('selectedTuitionRange'),
        selectedAdvancedCategories: this.getLocalData('selectedAdvancedCategories'),
        selectedActivities: this.getLocalData('selectedActivities'),
        campsSorted: this.getLocalData('campsSorted'),
      }
    },
    computed: {
      buttonText: function () {
        var campCount = this.campsSorted;
        return campCount.length && !this.noResults ? 
          "View results (" + campCount.length + ")" :
          "Nothing Found.";
      }
    },
    watch: {
      // Quick fixes!
      selectedCampCategory: function() { this.runSearch(); },
      selectedCampType: function() { this.runSearch(); },
      selectedSessionLength: function() { this.runSearch(); },
      selectedTuitionRange: function() { this.runSearch(); },
      selectedAdvancedCategories: function() { this.runSearch(); },
      selectedActivities: function() { this.runSearch(); },
      searchTerm: function () {
        this.noResults = false;
        Event.$emit('noResults', false);
        this.processing = true;
        this.debounceSearch().call();
      }
    },
    mounted() {
      this.getCamps();
      Event.$on('clearSearch', () => { this.resetSearch(); });
    },
    methods: {
      storeData() {
        var searchData = {
          searchTerm: this.searchTerm,
          campsSorted: this.campsSorted,
          selectedCampCategory: this.selectedCampCategory,
          selectedCampType: this.selectedCampType,
          selectedSessionLength: this.selectedSessionLength,
          selectedTuitionRange: this.selectedTuitionRange,
          selectedAdvancedCategories: this.selectedAdvancedCategories,
          selectedActivities: this.selectedActivities,
        }
        window.localStorage.setItem('campSearchData', JSON.stringify(searchData));
      },
      getLocalData(option, defaultReturn = []) {
        var possibleStorage = JSON.parse(window.localStorage.getItem('campSearchData'));
        return possibleStorage && possibleStorage[option] ? possibleStorage[option] : defaultReturn;
      },
      viewResults() {
        Event.$emit('campsReady', this.campsSorted);
        this.processing = false;
      },
      runSearch() {
        this.selectedCategories = true; // hack fix
        this.noResults = false;
        Event.$emit('noResults', false);
        this.processing = true;
        this.debounceSearch().call();
      },
      alphaCamps(camps) {
        return camps.sort(function(a, b) {
          var nameA = a.post_title.toUpperCase().replace('CAMP', '').trim();
          var nameB = b.post_title.toUpperCase().replace('CAMP', '').trim();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      },
      shuffleCamps(camps) {
        return this.alphaCamps(camps).sort(() => Math.random() - 0.5);
      },
      getSearchTerms() {
        return this.searchTerm;
      },
      searchCamps() {
        if (!this.getSearchTerms().length && !this.selectedCategories) {
          this.campsSorted = this.shuffleCamps(this.allCamps);
          this.viewResults();
        } else if (!this.getSearchTerms().length) {
          this.campsSorted = this.considerTerms(this.allCamps);
          this.viewResults();
        } else {
          this.$search(this.getSearchTerms(), this.allCamps, this.searchOptions).then(results => {
            this.campsSorted = this.considerTerms(results);
            this.viewResults();
          });
        }
        this.processing = false;
        this.storeData();
      },
      resetSearch() {
        this.noResults = false;
        Event.$emit('noResults', false);
        this.searchTerm = '';
        this.selectedCategories = false;
        this.selectedCampCategory = '';
        this.selectedCampType = [];
        this.selectedSessionLength = [];
        this.selectedTuitionRange = [];
        this.selectedAdvancedCategories = [];
        this.selectedActivities = [];
        this.searchCamps();
      },
      considerTerms(camps) {
        if (!this.selectedCategories) return camps;

          // Camps shown are either day camp or overnight camp
          camps = this.selectedCampCategory.length ? camps.filter((camp) => {
            return window._.includes(camp.all_cats, this.selectedCampCategory);
          }) : camps;

          // Then filter further to limit by type
          camps = this.selectedCampType.length ? camps.filter((camp) => {
            return window._.intersectionWith(this.selectedCampType, camp.all_cats).length;
          }) : camps;

          // Then filter further to limit by session length
          camps = this.selectedSessionLength.length ? camps.filter((camp) => {
            return window._.intersectionWith(this.selectedSessionLength, camp.all_cats).length;
          }) : camps;

          // Then filter further to limit by tuition range
          camps = this.selectedTuitionRange.length ? camps.filter((camp) => {
            return window._.intersectionWith(this.selectedTuitionRange, camp.all_cats).length;
          }) : camps;

          // Then filter further to limit by advanced cats (inclusive!)
          camps = this.selectedAdvancedCategories.length ? camps.filter((camp) => {
            return !window._.difference(this.selectedAdvancedCategories, camp.all_cats).length;
          }) : camps;

          // Then filter further to limit by activities (inclusive!)
          camps = this.selectedActivities.length ? camps.filter((camp) => {
            return !window._.difference(this.selectedActivities, camp.all_cats).length;
          }) : camps;

          if (!camps.length) {
            this.noResults = true;
            Event.$emit('noResults', true);
            return this.shuffleCamps(this.allCamps);
          }

        //   this.storeData();
          return camps;
      },
      debounceSearch () {
        var timeoutID = null;
        return () => {
          clearTimeout(timeoutID);
          timeoutID = setTimeout(() => {
            this.searchCamps();
          }, 1000);
        }
      },            
      getCamps() {
        this.processing = true;
        window.axios.get('/slickfish/v1/camps').then(response => {
          this.allCamps = response.data;
            this.getAvailableCats();
            if (!this.getLocalData('campsSorted').length) {
                this.campsSorted = this.shuffleCamps(response.data);
                this.runSearch();
            } 
          this.viewResults();
        }).catch(error => {
          this.processing = false;
          console.log(error);
        });
      },
      getAvailableCats() {
        this.processing = true;
        window.axios.get('/slickfish/v1/cats/all').then(response => {
          this.processing = false;
          this.availableCategories = response.data;
        }).catch(error => {
          this.processing = false;
          console.log(error);
        });
      },
    },
  }
</script>
