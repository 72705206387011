<template>
  <section
    id="find-camp-tool" 
    class="find-a-camp">
    <button 
      @click="openModal()" 
      class="button is-primary"><i class="fas fa-btn fa-search"/>{{ btnTxt }}</button>
    <div :class="{'modal': true, 'is-active': showModal}">
      <div 
        @click="closeModal()"
        class="modal-background" />
      <div class="modal-content">
        <div class="columns row-reverse content-overflown">
          <div class="column is-6 search-tools">
            <h1 class="fatty">Find-A-Camp</h1>
            <p>Search by name of a camp, county (i.e. Cumberland) or keyword(s) by using the input field below...</p>
            <div class="field">
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input 
                      v-model="searchTerm"
                      class="input"
                      type="text"
                      placeholder="Enter search terms">
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <p>...or select any combination of filters to see only camps which offer the following:</p>
            <div class="field">
              <div class="field-label">
                <label class="label">Camp Category:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <template v-if="!availableCategories.hasOwnProperty('camp-category')">
                      <span class="icon"><i class="fas fa-spin fa-spinner"/></span>Fetching...
                    </template>
                    <template 
                      v-else 
                      v-for="check in availableCategories['camp-category']">
                      <label 
                        :key="check.term_id" 
                        :for="'rd-' + check.term_id" 
                        class="radio">
                        <input 
                          :id="'rd-' + check.term_id" 
                          type="radio" 
                          v-model="selectedCampCategory" 
                          :value="check.name.replace('amp;', '')">
                        {{ check.name.replace('amp;', '') }}
                      </label>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="field-label">
                <label class="label">Camp Type:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <template v-if="!availableCategories.hasOwnProperty('camp-type')">
                      <span class="icon"><i class="fas fa-spin fa-spinner"/></span>Fetching...
                    </template>
                    <template 
                      v-else 
                      v-for="check in availableCategories['camp-type']">
                      <label 
                        :key="check.term_id" 
                        class="checkbox">
                        <input 
                          v-model="selectedCampType" 
                          :key="check.term_id" 
                          :value="check.name" 
                          type="checkbox">
                        {{ check.name.replace('amp;', '') }}
                      </label>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="field-label">
                <label class="label">Session Length:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <template v-if="!availableCategories.hasOwnProperty('session-length')">
                      <span class="icon"><i class="fas fa-spin fa-spinner"/></span>Fetching...
                    </template>
                    <template 
                      v-else 
                      v-for="check in availableCategories['session-length']">
                      <label 
                        class="checkbox"
                        :key="check.term_id">
                        <input 
                          v-model="selectedSessionLength" 
                          :key="check.term_id" 
                          :value="check.name" 
                          type="checkbox">
                        {{ check.name.replace('amp;', '') }}
                      </label>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="field-label">
                <label class="label">Tuition per Week:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <template v-if="!availableCategories.hasOwnProperty('tuition-range')">
                      <span class="icon"><i class="fas fa-spin fa-spinner"/></span>Fetching...
                    </template>
                    <template 
                      v-else 
                      v-for="check in availableCategories['tuition-range']">
                      <label 
                        class="checkbox"
                        :key="check.term_id">
                        <input 
                          v-model="selectedTuitionRange" 
                          :key="check.term_id" 
                          :value="check.name"
                          type="checkbox">
                        {{ check.name.replace('amp;', '') }}
                      </label>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="field-label">
                <label class="label">Advanced Categories:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <template v-if="!availableCategories.hasOwnProperty('advanced-search-cats')">
                      <span class="icon"><i class="fas fa-spin fa-spinner"/></span>Fetching...
                    </template>
                    <template 
                      v-else 
                      v-for="check in availableCategories['advanced-search-cats']">
                      <label 
                        class="checkbox"
                        :key="check.term_id">
                        <input 
                          v-model="selectedAdvancedCategories"  
                          :key="check.term_id" 
                          :value="check.name" 
                          type="checkbox">
                        {{ check.name.replace('amp;', '') }}
                      </label>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="field-label">
                <label class="label">Activities:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <template v-if="!availableCategories.hasOwnProperty('activities')">
                      <span class="icon"><i class="fas fa-spin fa-spinner"/></span>Fetching...
                    </template>
                    <template 
                      v-else 
                      v-for="check in availableCategories['activities']">
                      <label 
                        class="checkbox"
                        :key="check.term_id">
                        <input 
                          v-model="selectedActivities" 
                          :key="check.term_id" 
                          :value="check.name" 
                          type="checkbox">
                        {{ check.name.replace('amp;', '') }}
                      </label>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-6 camp-list-container">
            <ul class="camp-list">
              <li><span v-html="resultsHeader"/> <a 
                v-if="!processing" 
                @click="resetSearch()">reset search</a></li>
              <li
                style="display:none;"
                v-if="processing"/>
              <li 
                v-else
                :key="camp.ID"
                v-for="camp in campsSorted">
                <div class="media">
                  <template v-if="camp.logo">
                    <figure class="media-left"><a 
                      @click="toggleFullScreen(true)" 
                      :href="camp.url"><img 
                        :src="camp.logo" 
                        :alt="camp.post_title"></a></figure>
                  </template>
                  <div class="media-content">
                    <div class="content">
                      <h2 class="entry-title"><a 
                        @click="toggleFullScreen(true)" 
                        :href="camp.url">{{ camp.post_title }}</a></h2>
                      <ul class="camp-meta">
                        <li
                          v-for="(meta, i) in getMeta(camp)"
                          :key="i">{{ meta }}</li>
                      </ul>
                      <div class="entry-content">{{ getContent(camp) }}</div>
                    </div>
                    <nav class="level">
                      <div class="level-right read-more-wrap">
                        <a 
                          @click="toggleFullScreen(true)"
                          class="read-more" 
                          :href="camp.url">See More ></a>
                      </div>
                    </nav>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      <!-- <button 
        @click="findResults()" 
        class="button status-button" 
        aria-label="close"
        :text="buttonStatus"/> -->
      </div>
      <button 
        @click="closeModal()" 
        class="modal-close is-large" 
        aria-label="close"/>
    </div>
  </section>
</template>

<script>
    export default {
        props: {
          btnTxt: {
            type: String,
            default: "Open Search Tool"
          }
        },
        data() {
          return {
            searchTerm: this.getLocalData('searchTerm', ''),
            processing: true,
            campsSorted: this.getLocalData('campsSorted'),
            allCamps: {},
            showModal: false,
            noResults: false,
            modalData: {},
            searchOptions: {
              // keys: ['post_title', 'post_content', 'post_excerpt'],
              keys: ['post_title', 'post_content', 'post_excerpt', 'all_cats', 'categories'],
              threshold: 0.2
            },
            availableCategories: [],
            selectedCategories: false,
            selectedCampCategory: this.getLocalData('selectedCampCategory'),
            selectedCampType: this.getLocalData('selectedCampType'),
            selectedSessionLength: this.getLocalData('selectedSessionLength'),
            selectedTuitionRange: this.getLocalData('selectedTuitionRange'),
            selectedAdvancedCategories: this.getLocalData('selectedAdvancedCategories'),
            selectedActivities: this.getLocalData('selectedActivities'),
          }
        },
        computed: {
          statusButton: function() {
            return "ready to search";
          },
          resultsHeader: function () {
            if (this.noResults)
              return '<span class="icon"><i class="fas fa-warning"></i></span> No results found.';

            if (!this.processing && this.campsSorted && this.campsSorted.length)
              return 'Viewing ' + this.campsSorted.length + ' camps.';

            return '<span class="icon"><i class="fas fa-spin fa-spinner"></i></span>Fetching...';
          }
        },
        watch: {
            // Quick fixes!
            selectedCampCategory: function() { this.runSearch(); },
            selectedCampType: function() { this.runSearch(); },
            selectedSessionLength: function() { this.runSearch(); },
            selectedTuitionRange: function() { this.runSearch(); },
            selectedAdvancedCategories: function() { this.runSearch(); },
            selectedActivities: function() { this.runSearch(); },
            searchTerm: function () {
                this.noResults = false;
                this.processing = true;
                this.debounceSearch().call();
            },
        },
        mounted() {
            document.addEventListener("keydown", () => {
                var key = event.keyCode ? event.keyCode : event.which;
                if (this.showModal && key == 27) {
                    this.closeModal();
                }
            });
            this.getCamps();
            // this.openModal();
        },
        methods: {
          storeData() {
            var searchData = {
              searchTerm: this.searchTerm,
              campsSorted: this.campsSorted,
              selectedCampCategory: this.selectedCampCategory,
              selectedCampType: this.selectedCampType,
              selectedSessionLength: this.selectedSessionLength,
              selectedTuitionRange: this.selectedTuitionRange,
              selectedAdvancedCategories: this.selectedAdvancedCategories,
              selectedActivities: this.selectedActivities,
            }
            // console.log('searchData: ', searchData)
            window.localStorage.setItem('campSearchData', JSON.stringify(searchData));
          },
          getLocalData(option, defaultReturn = []) {
            var possibleStorage = JSON.parse(window.localStorage.getItem('campSearchData'));
            // console.log('possibleStorage', possibleStorage);
            return possibleStorage && possibleStorage[option] ? possibleStorage[option] : defaultReturn;
          },
          toggleFullScreen() {},
          // toggleFullScreen(cancel) {
          //   var doc = window.document;
          //   var docEl = doc.documentElement;

          //   var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
          //   var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

          //   if(!cancel && !doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
          //     requestFullScreen.call(docEl);
          //   }
          //   else {
          //     cancelFullScreen.call(doc);
          //   }
          // },
          runSearch() {
            this.selectedCategories = true; // hack fix
            this.noResults = false;
            this.processing = true;
            this.debounceSearch().call();
          },
            openModal() {
                this.showModal = true;
                document.documentElement.style.overflow = "hidden";
                document.getElementById('app').style['will-change'] = "unset";
                document.getElementById('app').style['overflow-x'] = "unset";
                this.toggleFullScreen();
            },
            closeModal() {
                document.documentElement.style.overflow = "unset";
                this.showModal = false;
                document.getElementById('app').style['will-change'] = "transform";
                document.getElementById('app').style['overflow-x'] = "hidden";
                this.toggleFullScreen(true);
            },
            alphaCamps(camps) {
                return camps.sort(function(a, b) {
                    var nameA = a.post_title.toUpperCase().replace('CAMP', '').trim();
                    var nameB = b.post_title.toUpperCase().replace('CAMP', '').trim();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });
            },
            shuffleCamps(camps) {
                return this.alphaCamps(camps).sort(() => Math.random() - 0.5);
            },
            getSearchTerms() {
                return this.searchTerm;
                // return this.searchTerm + ' ' + this.selectedCategories.join(' ');
            },
            searchCamps() {
              if (!this.getSearchTerms().length && !this.selectedCategories) {
                this.campsSorted = this.shuffleCamps(this.allCamps);
              } else if (!this.getSearchTerms().length) {
                this.campsSorted = this.considerTerms(this.allCamps);
              } else {
                console.log(this.allCamps);
                this.$search(this.getSearchTerms(), this.allCamps, this.searchOptions).then(results => {
                  this.campsSorted = this.considerTerms(results);
                });
              }
              this.processing = false;
              this.storeData();
            },
            resetSearch() {
                this.noResults = false;
                this.searchTerm = '';
                this.campsSorted = [];
                this.selectedCategories = false;
                this.selectedCampCategory = '';
                this.selectedCampType = [];
                this.selectedSessionLength = [];
                this.selectedTuitionRange = [];
                this.selectedAdvancedCategories = [];
                this.selectedActivities = [];
                this.searchCamps();
            },
            considerTerms(camps) {
              if (!this.selectedCategories) return camps;

                // Camps shown are either day camp or overnight camp
                camps = this.selectedCampCategory.length ? camps.filter((camp) => {
                    return window._.includes(camp.all_cats, this.selectedCampCategory);
                }) : camps;

                // Then filter further to limit by type
                camps = this.selectedCampType.length ? camps.filter((camp) => {
                    return window._.intersectionWith(this.selectedCampType, camp.all_cats).length;
                }) : camps;

                // Then filter further to limit by session length
                camps = this.selectedSessionLength.length ? camps.filter((camp) => {
                    return window._.intersectionWith(this.selectedSessionLength, camp.all_cats).length;
                }) : camps;

                // Then filter further to limit by tuition range
                camps = this.selectedTuitionRange.length ? camps.filter((camp) => {
                    return window._.intersectionWith(this.selectedTuitionRange, camp.all_cats).length;
                }) : camps;

                // Then filter further to limit by advanced cats (inclusive!)
                camps = this.selectedAdvancedCategories.length ? camps.filter((camp) => {
                    return !window._.difference(this.selectedAdvancedCategories, camp.all_cats).length;
                }) : camps;

                // Then filter further to limit by activities (inclusive!)
                camps = this.selectedActivities.length ? camps.filter((camp) => {
                    return !window._.difference(this.selectedActivities, camp.all_cats).length;
                }) : camps;

                if (!camps.length) {
                    this.noResults = true;
                    return this.shuffleCamps(this.allCamps);
                }

                // this.storeData();
                this.processing = false;
                return camps;
            },
            debounceSearch () {
                var timeoutID = null;
                return () => {
                    clearTimeout(timeoutID);
                    timeoutID = setTimeout(() => {
                        this.searchCamps();
                    }, 1000);
                }
            },
            getContent(camp) {
                var trimmedContent = this.stripHTML(camp.post_content).substr(0, 150);
                trimmedContent = trimmedContent.substr(0, Math.min(trimmedContent.length, trimmedContent.lastIndexOf(" "))) + '...';
                return camp.post_excerpt.length ? this.stripHTML(camp.post_excerpt) : trimmedContent;
            },
            stripHTML(html) {
                var tmp = document.createElement("div");
                tmp.innerHTML = html;
                return tmp.textContent || tmp.innerText || "";
            },
            getMeta(camp) {
                var meta = [];
                meta.push(camp.categories.filter(function(cat) {
                    return cat.taxonomy == 'camp-category';
                }).map(function(cat) {return cat.term.name}));
                meta.push(camp.categories.filter(function(cat) {
                    return cat.taxonomy == 'camp-type';
                }).map(function(cat) {return cat.term.name}));
                meta.push(camp.categories.filter(function(cat) {
                    return cat.taxonomy == 'session-length';
                }).map(function(cat) {return cat.term.name}));

                var string = meta
                    .filter(function(entry) { return /\S/.test(entry); })
                    .map(function(item) {
                        return item.join(', ');
                });
                return string;
            },
            getCamps() {
                this.processing = true;
                window.axios.get('/slickfish/v1/camps').then(response => {
                    this.allCamps = response.data;
                    this.getAvailableCats();
                    if (!this.getLocalData('campsSorted').length) {
                        this.campsSorted = this.shuffleCamps(response.data);
                        this.runSearch();
                    } 
                }).catch(error => {
                    this.processing = false;
                    console.log(error);
                });
            },
            getAvailableCats() {
                this.processing = true;
                window.axios.get('/slickfish/v1/cats/all').then(response => {
                    this.processing = false;
                    this.availableCategories = response.data;
                }).catch(error => {
                    this.processing = false;
                    console.log(error);
                });
            },
        },
    }
</script>
