<template>
  <div class="camp">
    <template v-if="processing">
      <span class="icon">
        <i class="fas fa-spin fa-spinner"/>
      </span>Loading...
    </template>
    <template v-else>
      <h2>{{ post.post_title }}</h2>
      <div class="columns">
        <div class="column is-12">
          <updatablefield 
            inline-template 
            :type="'post'"
            :handle="'post_content'"
            :current-data="post.post_content">
            <div class="field">
              <div :class="{'control': true, 'is-loading': processing, 'is-success': success,'is-danger': error}">
                <trumbowyg
                  @tbw-blur="updateData(data)"
                  v-model="data"
                  :svg-path="'/content/themes/slickfish/fonts/vendor/trumbowyg/dist/ui/icons.svg'"
                  :config="{btnsDef: {formattingLight: {dropdown: ['p','h2','h3'],ico: 'p'}},btns: [['undo', 'redo'], ['formattingLight'],['strong', 'em'],['link'],['unorderedList']]}"/>
                <small style="color: #565656">Maximum 250 words</small>
                <p :class="{'help': true, 'is-success': success,'is-danger': error}">
                  <template v-if="processing">Saving <i class="fas fa-spin fa-spinner"/></template>
                  <template v-if="success">Saved </template>
                  <template v-if="error">There was an error </template>
                  <span 
                    v-if="!processing"
                    v-html="message"/>
                </p>
              </div>
            </div>
          </updatablefield>
        </div>
      </div>
      <div class="columns">
        <div class="column is-12">
          <div class="form">
            <updatablefield
              inline-template
              :type="'post'"
              :handle="'post_excerpt'"
              :current-data="post.post_excerpt">
              <div class="field">
                <label class="label">Message Excerpt
                  <p 
                    v-show="success || error"
                    :class="{'help': true, 'is-success': success,'is-danger': error}"
                    v-html="message"/>
                </label>
                <div :class="{'control': true, 'is-loading': processing}">
                  <textarea 
                    @change="updateData()"
                    :class="{'textarea': true, 'is-success': success,'is-danger': error}"
                    type="text" 
                    placeholder=""
                    v-model="data"/>
                  <small style="color: #565656">Maximum 120 words</small>
                </div>
              </div>
            </updatablefield>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-12">
          <div class="form">
            <div class="columns">
              <div class="column is-3">
                <updatablefield 
                  inline-template 
                  :type="'meta'" 
                  :handle="'logo'" 
                  :current-data="meta.logo">
                  <div class="field">
                    <div :class="{'control': true, 'is-loading': processing}">
                      <label class="label">Logo
                        <p 
                          v-show="success || error"
                          :class="{'help': true, 'is-success': success,'is-danger': error}" 
                          v-html="message"/>
                      </label>
                      <div class="logo-container">
                        <img 
                          v-if="data.length" 
                          :class="{'is-success': success,'is-danger': error}" 
                          @click="openUploader()" 
                          :src="data">
                        <div v-else class="edit-btn" @click="openUploader()">
                          Add Logo
                        </div>
                      </div>
                    </div>
                  </div>
                </updatablefield>
              </div>
              <div class="column">
                <updatablefield 
                  inline-template 
                  :type="'meta'" 
                  :handle="'images'" 
                  :current-data="meta.images">
                  <div class="field"><div :class="{'control': true, 'is-loading': processing}">
                    <label class="label">Gallery Images <small style="color: #565656">(Upload no more than 9 images)</small>
                      <p 
                        v-show="success || error"
                        :class="{'help': true, 'is-success': success,'is-danger': error}" 
                        v-html="message"/>
                    </label>
                    <div class="columns is-multiline images-container">
                      <div 
                        class="column is-3"
                        v-for="img in data">
                        <img 
                          v-if="img.length"
                          :class="{'is-success': success,'is-danger': error}"
                          @click="openUploader()"
                          :src="img">
                      </div>
                      <div class="column is-3">
                        <div class="edit-btn" @click="openUploader()">
                          Edit Images
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </updatablefield>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-12">
          <h3>Fast Facts</h3>
          <div class="form">
            <div class="columns">
              <div class="column is-6">
                <h4>Ages</h4>
                <updatablefield
                  inline-template
                  :type="'meta'"
                  :handle="'youngest-age'"
                  :current-data="meta['youngest-age']">
                  <div class="field">
                    <label class="label">Youngest Age
                      <p
                        v-show="success || error"
                        :class="{'help': true, 'is-success': success,'is-danger': error}" 
                        v-html="message"/>
                    </label>
                    <div :class="{'control': true, 'is-loading': processing}">
                      <input
                        @change="updateData()"
                        :class="{'input': true, 'is-success': success,'is-danger': error}" 
                        type="text"
                        v-model="data"
                        placeholder="">
                    </div>
                  </div>
                </updatablefield>
                <updatablefield
                  inline-template
                  :type="'meta'"
                  :handle="'oldest-age'"
                  :current-data="meta['oldest-age']">
                  <div class="field">
                    <label class="label">Oldest Age
                      <p
                        v-show="success || error"
                        :class="{'help': true, 'is-success': success,'is-danger': error}" 
                        v-html="message"/>
                    </label>
                    <div :class="{'control': true, 'is-loading': processing}">
                      <input
                        @change="updateData()"
                        :class="{'input': true, 'is-success': success,'is-danger': error}" 
                        type="text"
                        v-model="data"
                        placeholder="">
                    </div>
                  </div>
                </updatablefield>
                <h4>Founded</h4>
                <updatablefield
                  inline-template
                  :type="'meta'"
                  :handle="'founded'"
                  :current-data="meta['founded']">
                  <div class="field">
                    <label class="label">Year
                      <p
                        v-show="success || error"
                        :class="{'help': true, 'is-success': success,'is-danger': error}" 
                        v-html="message"/>
                    </label>
                    <div :class="{'control': true, 'is-loading': processing}">
                      <input
                        @change="updateData()"
                        :class="{'input': true, 'is-success': success,'is-danger': error}" 
                        type="text"
                        v-model="data"
                        placeholder="">
                    </div>
                  </div>
                </updatablefield>
              </div>
              <div class="column">
                <h4>Camp Season</h4>
                <updatablefield
                  inline-template
                  :type="'meta'"
                  :handle="'begin-date'"
                  :current-data="meta['begin-date']">
                  <div class="field">
                    <label class="label">Begin Date
                      <p
                        v-show="success || error"
                        :class="{'help': true, 'is-success': success,'is-danger': error}" 
                        v-html="message"/>
                    </label>
                    <div :class="{'control': true, 'is-loading': processing}">
                      <input
                        @change="updateData()"
                        :class="{'input': true, 'is-success': success,'is-danger': error}" 
                        type="text"
                        v-model="data"
                        placeholder="">
                    </div>
                  </div>
                </updatablefield>
                <updatablefield
                  inline-template
                  :type="'meta'"
                  :handle="'end-date'"
                  :current-data="meta['end-date']">
                  <div class="field">
                    <label class="label">End Date
                      <p
                        v-show="success || error"
                        :class="{'help': true, 'is-success': success,'is-danger': error}" 
                        v-html="message"/>
                    </label>
                    <div :class="{'control': true, 'is-loading': processing}">
                      <input
                        @change="updateData()"
                        :class="{'input': true, 'is-success': success,'is-danger': error}" 
                        type="text"
                        v-model="data"
                        placeholder="">
                    </div>
                  </div>
                </updatablefield>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <h3>Extra Information</h3>
          <div class="form">
            <updatablefield
              inline-template
              :type="'meta'"
              :handle="'video'"
              :current-data="meta['video']">
              <div class="field">
                <label class="label">Video Link
                  <p
                    v-show="success || error"
                    :class="{'help': true, 'is-success': success,'is-danger': error}" 
                    v-html="message"/>
                </label>
                <div :class="{'control': true, 'is-loading': processing}">
                  <input
                    @change="updateData()"
                    :class="{'input': true, 'is-success': success,'is-danger': error}" 
                    type="text"
                    v-model="data"
                    placeholder="">
                </div>
              </div>
            </updatablefield>
            <updatablefield
              inline-template
              :type="'meta'"
              :handle="'website'"
              :current-data="meta['website']">
              <div class="field">
                <label class="label">Website Link
                  <p
                    v-show="success || error"
                    :class="{'help': true, 'is-success': success,'is-danger': error}" 
                    v-html="message"/>
                </label>
                <div :class="{'control': true, 'is-loading': processing}">
                  <input
                    @change="updateData()"
                    :class="{'input': true, 'is-success': success,'is-danger': error}"
                    type="text"
                    v-model="data"
                    placeholder="">
                </div>
              </div>
            </updatablefield>
          </div>
          <h3>Categories</h3>
          <div class="form">
            <updatablefield
              inline-template
              :type="'cats'"
              :handle="'camp-category'"
              :current-data="cats['camp-category']">
              <div class="field">
                <h3>Camp Category</h3>
                <div :class="{'control': true, 'is-loading': processing,'has-checkboxes': true}">
                  <p
                    v-show="success || error"
                    :class="{'help': true, 'is-success': success,'is-danger': error}" 
                    v-html="message"/>
                  <label
                    v-for="option in possibleData"
                    class="checkbox">
                    <input 
                      @change="updateData()"
                      :value="option.slug"
                      type="checkbox"
                      v-model="data">
                    {{ option.name }}
                  </label>
                </div>
              </div>
            </updatablefield>
            <updatablefield
              inline-template
              :type="'cats'"
              :handle="'camp-type'"
              :current-data="cats['camp-type']">
              <div class="field">
                <h3>Camp Type</h3>
                <div :class="{'control': true, 'is-loading': processing,'has-checkboxes': true}">
                  <p 
                    v-show="success || error"
                    :class="{'help': true, 'is-success': success,'is-danger': error}" 
                    v-html="message"/>
                  <label
                    v-for="option in possibleData" 
                    class="checkbox">
                    <input 
                      @change="updateData()" 
                      :value="option.slug" 
                      type="checkbox" 
                      v-model="data">
                    {{ option.name }}
                  </label>
                </div>
              </div>
            </updatablefield>
            <updatablefield
              inline-template
              :type="'cats'"
              :handle="'session-length'"
              :current-data="cats['session-length']">
              <div class="field">
                <h3>Session Length</h3>
                <div :class="{'control': true, 'is-loading': processing,'has-checkboxes': true}">
                  <p 
                    v-show="success || error"
                    :class="{'help': true, 'is-success': success,'is-danger': error}" 
                    v-html="message"/>
                  <label
                    v-for="option in possibleData"
                    class="checkbox">
                    <input
                      @change="updateData()"
                      :value="option.slug"
                      type="checkbox"
                      v-model="data">
                    {{ option.name }}
                  </label>
                </div>
              </div>
            </updatablefield>
            <updatablefield
              inline-template
              :type="'cats'"
              :handle="'tuition-range'"
              :current-data="cats['tuition-range']">
              <div class="field">
                <h3>Tuition per Week</h3>
                <div :class="{'control': true, 'is-loading': processing,'has-checkboxes': true}">
                  <p 
                    v-show="success || error" 
                    :class="{'help': true, 'is-success': success,'is-danger': error}" 
                    v-html="message"/>
                  <label 
                    v-for="option in possibleData"
                    class="checkbox">
                    <input
                      @change="updateData()"
                      :value="option.slug" 
                      type="checkbox" 
                      v-model="data">
                    {{ option.name }}
                  </label>
                </div>
              </div>
            </updatablefield>
            <updatablefield 
              inline-template
              :type="'cats'" 
              :handle="'advanced-search-cats'" 
              :current-data="cats['advanced-search-cats']">
              <div class="field">
                <h3>Advanced Search Categories</h3>
                <div :class="{'control': true, 'is-loading': processing,'has-checkboxes': true}">
                  <p 
                    v-show="success || error" 
                    :class="{'help': true, 'is-success': success,'is-danger': error}" 
                    v-html="message"/>
                  <label 
                    v-for="option in possibleData"
                    class="checkbox">
                    <input
                      @change="updateData()"
                      :value="option.slug" 
                      type="checkbox" 
                      v-model="data">
                    {{ option.name }}
                  </label>
                </div>
              </div>
            </updatablefield>
            <updatablefield 
              inline-template
              :type="'cats'"
              :handle="'activities'" 
              :current-data="cats['activities']">
              <div class="field">
                <h3>Activities</h3>
                <div :class="{'control': true, 'is-loading': processing,'has-checkboxes': true}">                
                  <p 
                    v-show="success || error" 
                    :class="{'help': true, 'is-success': success,'is-danger': error}" 
                    v-html="message"/>
                  <label 
                    v-for="option in possibleData"
                    class="checkbox">
                    <input
                      @change="updateData()"
                      :value="option.slug" 
                      type="checkbox" 
                      v-model="data">
                    {{ option.name }}
                  </label>
                </div>
              </div>
            </updatablefield>
          </div>
        </div>
        <div class="column">
          <h3>Contact Information</h3>
          <div class="form">
            <updatablefield
              inline-template
              :type="'meta'"
              :handle="'address-one'"
              :current-data="meta['address-one']">
              <div class="field">
                <label class="label">Street
                  <p 
                    v-show="success || error" 
                    :class="{'help': true, 'is-success': success,'is-danger': error}" 
                    v-html="message"/>
                </label>
                <div :class="{'control': true, 'is-loading': processing}">
                  <input
                    @change="updateData()"
                    :class="{'input': true, 'is-success': success,'is-danger': error}"
                    type="text"
                    v-model="data"
                    placeholder="">
                </div>
              </div>
            </updatablefield>
            <updatablefield
              inline-template
              :type="'meta'"
              :handle="'address-two'"
              :current-data="meta['address-two']">
              <div class="field">
                <label class="label">Address Two
                  <p 
                    v-show="success || error" 
                    :class="{'help': true, 'is-success': success,'is-danger': error}" 
                    v-html="message"/>
                </label>
                <div :class="{'control': true, 'is-loading': processing}">
                  <input
                    @change="updateData()"
                    :class="{'input': true, 'is-success': success,'is-danger': error}"
                    type="text"
                    v-model="data"
                    placeholder="">
                </div>
              </div>
            </updatablefield>
            <div class="field is-grouped">
              <div class="field-body">
                <updatablefield
                  inline-template
                  :type="'meta'"
                  :handle="'city'"
                  :current-data="meta['city']">
                  <div class="field">
                    <label class="label">City
                      <p 
                        v-show="success || error" 
                        :class="{'help': true, 'is-success': success,'is-danger': error}" 
                        v-html="message"/>
                    </label>
                    <div :class="{'control': true, 'is-loading': processing}">
                      <input
                        @change="updateData()"
                        :class="{'input': true, 'is-success': success,'is-danger': error}"
                        type="text"
                        v-model="data"
                        placeholder="">
                    </div>
                  </div>
                </updatablefield>
                <updatablefield
                  inline-template
                  :type="'meta'"
                  :handle="'state'"
                  :current-data="meta['state']">
                  <div class="field">
                    <label class="label">State
                      <p 
                        v-show="success || error" 
                        :class="{'help': true, 'is-success': success,'is-danger': error}" 
                        v-html="message"/>
                    </label>
                    <div :class="{'control': true, 'is-loading': processing}">
                      <input
                        @change="updateData()"
                        :class="{'input': true, 'is-success': success,'is-danger': error}"
                        type="text"
                        v-model="data"
                        placeholder="">
                    </div>
                  </div>
                </updatablefield>
                <updatablefield
                  inline-template
                  :type="'meta'"
                  :handle="'zip'"
                  :current-data="meta['zip']">
                  <div class="field">
                    <label class="label">Zip
                      <p 
                        v-show="success || error" 
                        :class="{'help': true, 'is-success': success,'is-danger': error}" 
                        v-html="message"/>
                    </label>
                    <div :class="{'control': true, 'is-loading': processing}">
                      <input 
                        @change="updateData()"
                        style="width: 100px;" 
                        :class="{'input': true, 'is-success': success,'is-danger': error}"
                        type="text"
                        v-model="data"
                        placeholder="">
                    </div>
                  </div>
                </updatablefield>
              </div>
            </div>
            <updatablefield 
              inline-template
              :type="'cats'"
              :handle="'county'"
              :current-data="cats['county']">
              <div class="field">
                <label class="label">County
                  <p 
                    v-show="success || error" 
                    :class="{'help': true, 'is-success': success,'is-danger': error}" 
                    v-html="message"/>
                </label>
                <div :class="{'control': true}">
                  <div :class="{'select': true, 'is-loading': processing, 'is-success': success,'is-danger': error}">
                    <select @change="updateData()" v-model="data[0]">
                      <option :value="option.slug" v-for="option in possibleData">{{ option.name }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </updatablefield>
            <div class="field is-grouped">
              <div class="field-body">
                <updatablefield
                  inline-template
                  :type="'meta'"
                  :handle="'phone'"
                  :current-data="meta['phone']">
                  <div class="field">
                    <label class="label">Phone
                      <p 
                        v-show="success || error" 
                        :class="{'help': true, 'is-success': success,'is-danger': error}" 
                        v-html="message"/>
                    </label>
                    <div :class="{'control': true, 'is-loading': processing}">
                      <input
                        @change="updateData()"
                        :class="{'input': true, 'is-success': success,'is-danger': error}"
                        type="text"
                        v-model="data"
                        placeholder="">
                    </div>
                  </div>
                </updatablefield>
                <updatablefield
                  inline-template
                  :type="'meta'"
                  :handle="'fax'"
                  :current-data="meta['fax']">
                  <div class="field">
                    <label class="label">Fax
                      <p 
                        v-show="success || error" 
                        :class="{'help': true, 'is-success': success,'is-danger': error}" 
                        v-html="message"/>
                    </label>
                    <div :class="{'control': true, 'is-loading': processing}">
                      <input
                        @change="updateData()"
                        :class="{'input': true, 'is-success': success,'is-danger': error}"
                        type="text"
                        v-model="data"
                        placeholder="">
                    </div>
                  </div>
                </updatablefield>
              </div>
            </div>
            <div class="field is-grouped">
              <div class="field-body">
                <updatablefield
                  inline-template
                  :type="'meta'"
                  :handle="'email'"
                  :current-data="meta['email']">
                  <div class="field">
                    <label class="label">Email
                      <p 
                        v-show="success || error" 
                        :class="{'help': true, 'is-success': success,'is-danger': error}" 
                        v-html="message"/>
                    </label>
                    <div :class="{'control': true, 'is-loading': processing}">
                      <input
                        @change="updateData()"
                        :class="{'input': true, 'is-success': success,'is-danger': error}"
                        type="text"
                        v-model="data"
                        placeholder="">
                    </div>
                  </div>
                </updatablefield>
                <updatablefield
                  inline-template
                  :type="'meta'"
                  :handle="'email-two'"
                  :current-data="meta['email-two']">
                  <div class="field">
                    <label class="label">Email Alt
                      <p 
                        v-show="success || error" 
                        :class="{'help': true, 'is-success': success,'is-danger': error}" 
                        v-html="message"/>
                    </label>
                    <div :class="{'control': true, 'is-loading': processing}">
                      <input
                        @change="updateData()"
                        :class="{'input': true, 'is-success': success,'is-danger': error}"
                        type="text"
                        v-model="data"
                        placeholder="">
                    </div>
                  </div>
                </updatablefield>
              </div>
            </div>
            <div class="field is-grouped">
              <div class="field-body">
                <updatablefield
                  inline-template
                  :type="'meta'"
                  :handle="'contact-one-name'"
                  :current-data="meta['contact-one-name']">
                  <div class="field">
                    <label class="label">Contact Name
                      <p 
                        v-show="success || error" 
                        :class="{'help': true, 'is-success': success,'is-danger': error}" 
                        v-html="message"/>
                    </label>
                    <div :class="{'control': true, 'is-loading': processing}">
                      <input
                        @change="updateData()"
                        :class="{'input': true, 'is-success': success,'is-danger': error}"
                        type="text"
                        v-model="data"
                        placeholder="">
                    </div>
                  </div>
                </updatablefield>
                <updatablefield
                  inline-template
                  :type="'meta'"
                  :handle="'contact-one-title'"
                  :current-data="meta['contact-one-title']">
                  <div class="field">
                    <label class="label">Title
                      <p 
                        v-show="success || error" 
                        :class="{'help': true, 'is-success': success,'is-danger': error}" 
                        v-html="message"/>
                    </label>
                    <div :class="{'control': true, 'is-loading': processing}">
                      <input
                        @change="updateData()"
                        :class="{'input': true, 'is-success': success,'is-danger': error}"
                        type="text"
                        v-model="data"
                        placeholder="">
                    </div>
                  </div>
                </updatablefield>
              </div>
            </div>
            <div class="field is-grouped">
              <div class="field-body">
                <updatablefield
                  inline-template
                  :type="'meta'"
                  :handle="'contact-two-name'"
                  :current-data="meta['contact-two-name']">
                  <div class="field">
                    <label class="label">C. Name (Alt)
                      <p 
                        v-show="success || error" 
                        :class="{'help': true, 'is-success': success,'is-danger': error}" 
                        v-html="message"/>
                    </label>
                    <div :class="{'control': true, 'is-loading': processing}">
                      <input
                        @change="updateData()"
                        :class="{'input': true, 'is-success': success,'is-danger': error}"
                        type="text"
                        v-model="data"
                        placeholder="">
                    </div>
                  </div>
                </updatablefield>
                <updatablefield
                  inline-template
                  :type="'meta'"
                  :handle="'contact-two-title'"
                  :current-data="meta['contact-two-title']">
                  <div class="field">
                    <label class="label">Title
                      <p 
                        v-show="success || error" 
                        :class="{'help': true, 'is-success': success,'is-danger': error}" 
                        v-html="message"/>
                    </label>
                    <div :class="{'control': true, 'is-loading': processing}">
                      <input
                        @change="updateData()"
                        :class="{'input': true, 'is-success': success,'is-danger': error}"
                        type="text"
                        v-model="data"
                        placeholder="">
                    </div>
                  </div>
                </updatablefield>
              </div>
            </div>                    
          </div>
          <h3>Winter Contact Information</h3>
          <div class="form">
            <updatablefield
              inline-template
              :type="'meta'"
              :handle="'winter-address-one'"
              :current-data="meta['winter-address-one']">
              <div class="field">
                <label class="label">Street
                  <p 
                    v-show="success || error" 
                    :class="{'help': true, 'is-success': success,'is-danger': error}" 
                    v-html="message"/>
                </label>
                <div :class="{'control': true, 'is-loading': processing}">
                  <input
                    @change="updateData()"
                    :class="{'input': true, 'is-success': success,'is-danger': error}"
                    type="text"
                    v-model="data"
                    placeholder="">
                </div>
              </div>
            </updatablefield>
            <updatablefield
              inline-template
              :type="'meta'"
              :handle="'winter-address-two'"
              :current-data="meta['winter-address-two']">
              <div class="field">
                <label class="label">Address Two
                  <p 
                    v-show="success || error" 
                    :class="{'help': true, 'is-success': success,'is-danger': error}" 
                    v-html="message"/>
                </label>
                <div :class="{'control': true, 'is-loading': processing}">
                  <input
                    @change="updateData()"
                    :class="{'input': true, 'is-success': success,'is-danger': error}"
                    type="text"
                    v-model="data"
                    placeholder="">
                </div>
              </div>
            </updatablefield>
            <div class="field is-grouped">
              <div class="field-body">
                <updatablefield
                  inline-template
                  :type="'meta'"
                  :handle="'winter-city'"
                  :current-data="meta['winter-city']">
                  <div class="field">
                    <label class="label">City
                      <p 
                        v-show="success || error" 
                        :class="{'help': true, 'is-success': success,'is-danger': error}" 
                        v-html="message"/>
                    </label>
                    <div :class="{'control': true, 'is-loading': processing}">
                      <input
                        @change="updateData()"
                        :class="{'input': true, 'is-success': success,'is-danger': error}"
                        type="text"
                        v-model="data"
                        placeholder="">
                    </div>
                  </div>
                </updatablefield>
                <updatablefield
                  inline-template
                  :type="'meta'"
                  :handle="'winter-state'"
                  :current-data="meta['winter-state']">
                  <div class="field">
                    <label class="label">State
                      <p 
                        v-show="success || error" 
                        :class="{'help': true, 'is-success': success,'is-danger': error}" 
                        v-html="message"/>
                    </label>
                    <div :class="{'control': true, 'is-loading': processing}">
                      <input
                        @change="updateData()"
                        :class="{'input': true, 'is-success': success,'is-danger': error}"
                        type="text"
                        v-model="data"
                        placeholder="">
                    </div>
                  </div>
                </updatablefield>
                <updatablefield
                  inline-template
                  :type="'meta'"
                  :handle="'winter-zip'"
                  :current-data="meta['winter-zip']">
                  <div class="field">
                    <label class="label">Zip
                      <p 
                        v-show="success || error" 
                        :class="{'help': true, 'is-success': success,'is-danger': error}" 
                        v-html="message"/>
                    </label>
                    <div :class="{'control': true, 'is-loading': processing}">
                      <input 
                        @change="updateData()" 
                        style="width: 100px;" 
                        :class="{'input': true, 'is-success': success,'is-danger': error}"
                        type="text"
                        v-model="data"
                        placeholder="">
                    </div>
                  </div>
                </updatablefield>
              </div>
            </div>
            
            <div class="field is-grouped"><!-- slickfish add in Winter Phone and Fax to camp admin -->
              <div class="field-body">
                <updatablefield
                  inline-template
                  :type="'meta'"
                  :handle="'winter-phone'"
                  :current-data="meta['winter-phone']">
                  <div class="field">
                    <label class="label">Phone
                      <p 
                        v-show="success || error" 
                        :class="{'help': true, 'is-success': success,'is-danger': error}" 
                        v-html="message"/>
                    </label>
                    <div :class="{'control': true, 'is-loading': processing}">
                      <input
                        @change="updateData()"
                        :class="{'input': true, 'is-success': success,'is-danger': error}"
                        type="text"
                        v-model="data"
                        placeholder="">
                    </div>
                  </div>
                </updatablefield>
                <updatablefield
                  inline-template
                  :type="'meta'"
                  :handle="'winter-fax'"
                  :current-data="meta['winter-fax']">
                  <div class="field">
                    <label class="label">Fax
                      <p 
                        v-show="success || error" 
                        :class="{'help': true, 'is-success': success,'is-danger': error}" 
                        v-html="message"/>
                    </label>
                    <div :class="{'control': true, 'is-loading': processing}">
                      <input
                        @change="updateData()"
                        :class="{'input': true, 'is-success': success,'is-danger': error}"
                        type="text"
                        v-model="data"
                        placeholder="">
                    </div>
                  </div>
                </updatablefield>
              </div>
            </div>            
            
            
            
            <div class="field is-grouped">
              <div class="field-body">
                <updatablefield
                  inline-template
                  :type="'meta'"
                  :handle="'winter-email'"
                  :current-data="meta['winter-email']">
                  <div class="field">
                    <label class="label">Email
                      <p 
                        v-show="success || error" 
                        :class="{'help': true, 'is-success': success,'is-danger': error}" 
                        v-html="message"/>
                    </label>
                    <div :class="{'control': true, 'is-loading': processing}">
                      <input
                        @change="updateData()"
                        :class="{'input': true, 'is-success': success,'is-danger': error}"
                        type="text"
                        v-model="data"
                        placeholder="">
                    </div>
                  </div>
                </updatablefield>
                <updatablefield
                  inline-template
                  :type="'meta'"
                  :handle="'winter-email-two'"
                  :current-data="meta['winter-email-two']">
                  <div class="field">
                    <label class="label">Email Alt
                      <p 
                        v-show="success || error" 
                        :class="{'help': true, 'is-success': success,'is-danger': error}" 
                        v-html="message"/>
                    </label>
                    <div :class="{'control': true, 'is-loading': processing}">
                      <input
                        @change="updateData()"
                        :class="{'input': true, 'is-success': success,'is-danger': error}"
                        type="text"
                        v-model="data"
                        placeholder="">
                    </div>
                  </div>
                </updatablefield>
              </div>
            </div>                   
            <div class="field is-grouped">
              <div class="field-body">
                <updatablefield
                  inline-template
                  :type="'meta'"
                  :handle="'winter-contact-one-name'"
                  :current-data="meta['winter-contact-one-name']">
                  <div class="field">
                    <label class="label">Contact Name
                      <p 
                        v-show="success || error" 
                        :class="{'help': true, 'is-success': success,'is-danger': error}" 
                        v-html="message"/>
                    </label>
                    <div :class="{'control': true, 'is-loading': processing}">
                      <input
                        @change="updateData()"
                        :class="{'input': true, 'is-success': success,'is-danger': error}"
                        type="text"
                        v-model="data"
                        placeholder="">
                    </div>
                  </div>
                </updatablefield>
                <updatablefield
                  inline-template
                  :type="'meta'"
                  :handle="'winter-contact-one-title'"
                  :current-data="meta['winter-contact-one-title']">
                  <div class="field">
                    <label class="label">Title
                      <p 
                        v-show="success || error" 
                        :class="{'help': true, 'is-success': success,'is-danger': error}" 
                        v-html="message"/>
                    </label>
                    <div :class="{'control': true, 'is-loading': processing}">
                      <input
                        @change="updateData()"
                        :class="{'input': true, 'is-success': success,'is-danger': error}"
                        type="text"
                        v-model="data"
                        placeholder="">
                    </div>
                  </div>
                </updatablefield>
              </div>
            </div>                   
            <div class="field is-grouped">
              <div class="field-body">
                <updatablefield
                  inline-template
                  :type="'meta'"
                  :handle="'winter-contact-two-name'"
                  :current-data="meta['winter-contact-two-name']">
                  <div class="field">
                    <label class="label">C. Name (Alt)
                      <p 
                        v-show="success || error" 
                        :class="{'help': true, 'is-success': success,'is-danger': error}" 
                        v-html="message"/>
                    </label>
                    <div :class="{'control': true, 'is-loading': processing}">
                      <input
                        @change="updateData()"
                        :class="{'input': true, 'is-success': success,'is-danger': error}"
                        type="text"
                        v-model="data"
                        placeholder="">
                    </div>
                  </div>
                </updatablefield>
                <updatablefield
                  inline-template
                  :type="'meta'"
                  :handle="'winter-contact-two-title'"
                  :current-data="meta['winter-contact-two-title']">
                  <div class="field">
                    <label class="label">Title
                      <p 
                        v-show="success || error"
                        :class="{'help': true, 'is-success': success,'is-danger': error}"
                        v-html="message"/>
                    </label>
                    <div :class="{'control': true, 'is-loading': processing}">
                      <input
                        @change="updateData()"
                        :class="{'input': true, 'is-success': success,'is-danger': error}"
                        type="text"
                        v-model="data"
                        placeholder="">
                    </div>
                  </div>
                </updatablefield>
              </div>
            </div>
          </div>
          <button 
            @click="fakeSave()"
            style="margin-top:1rem" 
            :class="{'button':true, 'is-primary':true, 'is-loading':saving}">
            <template v-if="saving">Saving...</template>
            <template v-else>Save</template>
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
    export default {
        props: {
          camp: {
            type: Number,
            default: 0
          },
          user: {
            type: Number,
            default: 0
          }
        },
        data() {
            return {
                campData: {},
                showModal: false,
                processing: true,
                saving: false,
                post: {},
                meta: {},
                cats: {}
            }
        },
        mounted() {
            this.getCampData();
            this.$on('saving', function(value){
                this.saving = value;
            });
        },
        methods: {
            fakeSave() {
                var _this = this;
                if (!_this.saving) {
                    _this.saving = true;
                    setTimeout(function() {
                        _this.saving = false;
                    }, 1000);
                }
            },
            openModal() {
                this.showModal = true;
                document.documentElement.style.overflow = "hidden";
            },
            closeModal() {
                document.documentElement.style.overflow = "auto";
                this.showModal = false;
            },
            getCampData() {
                this.processing = true;
                axios.get('/slickfish/v1/member/camp', {
                    params: {user_id: this.user, camp_id: this.camp}
                }).then(response => {
                    this.processing = false;
                    this.post = response.data.post;
                    this.meta = response.data.meta;
                    this.cats = response.data.cats;
                    console.log(response.data);
                }).catch(error => {
                    this.processing = false;
                    console.log(error);
                });
            }
        },
        components: {
            'updatablefield': {
                props: ['type', 'handle', 'currentData'],
                data() {
                    return {
                        data: {},
                        possibleData: {},
                        processing: false,
                        success: false,
                        error: false,
                        imageIds: []
                    }
                },
                mounted() {
                    if (this.handle == 'logo' || this.handle == 'images') {
                        this.makeFileFrame();
                        this.getLogo();
                    } else if (this.type == 'cats') {
                        this.fetchPossibleData();
                        this.data = this.currentData;
                    } else {
                        this.data = this.currentData;
                    }
                },
                methods: {
                    makeFileFrame() {
                        // Store ids as possible data
                        this.possibleData = this.currentData;
                        
                        // Create a frame
                        var title = (this.handle == 'logo') ? "Update Logo" : "Select up to 9 images";
                        this.file_frame = wp.media.frames.file_frame = wp.media({
                            title: title,
                            library: {type: 'image'},
                            button: {
                                text: 'Apply',
                            },
                            multiple: true
                        });

                        // Cache stuff
                        var frame = this.file_frame;
                        var _this = this;
                        var img = [];

                        frame.on( 'select', function() {
                            if (_this.handle == 'logo') {
                                var attachment = frame.state().get('selection').first().toJSON();
                                _this.data = attachment.url;
                                _this.imageIds = attachment.id;
                            }
                            else {
                                _this.data = [];
                                frame.state().get('selection').each(function(image, i) {
                                    if (image.attributes.sizes.thumbnail && i < 9) {
                                        _this.data.push(image.attributes.sizes.thumbnail.url);
                                        _this.imageIds.push(image.id);
                                    }
                                    console.log(image);
                                });
                            }
                            if (Array.isArray(_this.imageIds)) {
                              _this.updateData(_this.imageIds.splice(0,9));
                            } else {
                              _this.updateData(_this.imageIds);
                            }
                        });

                        frame.on('open',function() {
                            var selection = frame.state().get('selection');
                            var ids = (_this.imageIds.length) ? _this.imageIds : _this.currentData;
                            console.log(ids);
                            var attachment;
                            Array.isArray(ids) && ids.forEach(function(id) {
                                attachment = wp.media.attachment(id);
                                attachment.fetch();
                                selection.add( attachment ? [ attachment ] : [] );
                            });
                        });
                    },
                    openUploader() {
                        this.file_frame.open();
                    },
                    getLogo() {
                        this.processing = true;
                        axios.get('/slickfish/v1/camp/logo', {
                            params: {data: this.currentData},
                        }).then(response => {
                            this.processing = false;
                            console.log(response.data);
                            this.data = response.data;
                        }).catch(error => {
                            this.processing = false;
                            console.log(error);
                        });                        
                    },
                    updateData(data = null) {
                        this.processing = true;
                        this.success = false;
                        this.error = false;
                        data = (data) ? data : this.data;
                        this.$parent.$emit('saving', true);
                        axios.post('/slickfish/v1/camp/update', {
                            user: this.$parent.user,
                            camp: this.$parent.camp,
                            data: data,
                            type: this.type,
                            handle: this.handle,
                        }).then(response => {
                            this.$parent.$emit('saving', false);
                            this.processing = false;
                            this.success = true;
                            this.message = '<i class="fas fa-check"></i>';
                        }).catch(error => {
                            this.$parent.$emit('saving', false);
                            this.processing = false;
                            this.error = true;
                            this.message = '<i class="fas fa-times"></i>';
                            console.log(error);
                        });                        
                    },
                    fetchPossibleData() {

                        // We would only need to fetch possible categories
                        this.processing = true;
                        axios.get('/slickfish/v1/cats', {
                            params: {cat: this.handle}
                        }).then(response => {
                            this.processing = false;
                            this.possibleData = response.data;
                        }).catch(error => {
                            this.processing = false;
                            console.log(error);
                        });
                    }
                }
            }
        }
    }
</script>
